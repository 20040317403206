<template>
  <div v-if="!isEdiding">
    <bulma-carousel-modal :active="modalOpen"
      :closable="true"
      :start-index="selectedImage"
      :image-ids="imageIds"
      :image-height="1000"
      :image-width="1000"
      @close="close" />

    <div class="is-flex is-flex-wrap-wrap"
      v-if="!isLoadingImages">
      <div v-for="(image, index) in imagesWithInfo"
        :key="image.quoteImageId"
        class="p-2">
        <div v-if="image.imageContent"
          :class="'card'"
          style="box-shadow: 0 0 0 1px #d9d9d9">

          <header class="card-header">

            <div class="card-header-title p-1 has-background-grey-lighter is-flex is-justify-content-end">
              <a class="has-text-primary is-outlined"
                @click="editImage(image.quoteImageId)">
                <span class="icon">
                  <i class="mdi mdi-lead-pencil mdi-24px"
                    aria-hidden="true" />
                </span>
              </a>
            </div>

          </header>

          <div class="card-image">
            <a @click="viewImage(index)">
              <figure class="image is-128x128">
                <div style="position: relative">
                  <i v-if="image.isPrimary"
                    class="mdi mdi-star mdi-24px has-text-gold"
                    style="position: absolute; z-index: 1" />
                  <img v-if="image.imageContent"
                    class="image is-128x128"
                    style="object-fit: cover;"
                    :src="image.imageContent"
                    :key="image.quoteImageId"
                    :id="image.quoteImageId">
                </div>
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-show=" isLoadingImages"
      class="columns is-multiline">
      <div class="section">
        <span class="is-loading-image has-text-black has-text-centered" />
      </div>
    </div>

  </div>
</template>

<script>
import QuoteService from '@/services/QuoteService'
import BulmaCarouselModal from '../../components/BulmaCarousel/BulmaCarouselModal.vue'
import { OpenImage } from '@/components/VuePainterro'

export default {
  name: 'QuoteImages',
  components: { BulmaCarouselModal },
  props: {
    value: null
  },
  data() {
    return {
      size: 200,
      isLoadingImages: true,
      images: [],
      imageIds: [],
      selectedImage: null,
      modalOpen: false,
      imagesWithInfo: [],
      isEdiding: false
    }
  },
  created() {
    document.title = 'Images for Quote ' + this.value.quoteNo
    this.getThumbnails()
  },
  methods: {
    async getThumbnail(id, index) {
      var thumbnail = await QuoteService.getImageThumbnail(id, this.size)
      this.images.splice(index, 1, thumbnail)
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      const promises = this.imageIds.map(function (id, index) {
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.isLoadingImages = false
      this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
    },
    viewImage(index) {
      this.modalOpen = true
      this.selectedImage = index
    },
    close() {
      this.modalOpen = false
      this.selectedImage = null
    },
    closed() {
      window.scroll(this.lastScrollX, this.lastScrollY)
      this.isEdiding = false
    },
    editImage(id) {
      this.$showSpinner()
      this.lastScrollX = window.scrollX
      this.lastScrollY = window.scrollY
      window.scrollTo(0, 0)
      OpenImage(id, this.saved, this.closed, false)
      this.$hideSpinner()
      this.isEdiding = true
    }
  }
}
</script>

<style scoped>
.is-loading-image {
  left: 45%;
}
</style>