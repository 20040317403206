// import VuePainterro from './VuePainterro'
import PainterroService from './PainterroService'
import Guid from '@/components/Guid'
// import Painterro from 'painterro'
// let Painterro = null
// import(/* webpackChunkName: "painterro-chunk" */ 'painterro').then(({ default: painterro }) => { return Painterro = painterro })

const defaultMimeType = 'image/jpeg'

const OpenImage = async function(id, saveCallback, closeCallBack, closeOnSave = false) {
  if (Guid.validGuid(id)) {
    const image = await PainterroService.getImage(id)
    // import(/* webpackChunkName: "painterro-chunk" */ 'painterro').then(({ default: Painterro }) => {
    const { default: Painterro } = await import(/* webpackChunkName: "painterro-chunk" */ 'painterro')
    Painterro({
      hiddenTools: ['open'],
      defaultTool: 'arrow',
      // availableArrowLengths: [10,15,20,25,30,35,40],
      defaultArrowLength: 15,
      // colorScheme: {
      //   main: '#008cba',
      //   control: '#ffffff',
      //   controlContent: '#008cba',
      //   hoverControl: 'darkgray', //'#f2f2f2',
      //   hoverControlContent: '#008cba',
      //   activeControl: 'darkgray',
      //   activeControlContent: '#ffffff',
      //   inputText: 'black'
      // },
      colorScheme: {
        // main: '#008cba',
        control: '#008cba',
        controlContent: '#ffffff',
        hoverControl: '#006687',
        hoverControlContent: '#ffffff',
        activeControl: '#006687',
        activeControlContent: '#ffffff',
        inputText: 'black',
        toolControlNameColor: '#5bc0de'
      },
      saveHandler: async function(image, done) {
        const formData = new FormData()
        formData.append('image', image.asBlob(defaultMimeType), image.suggestedFileName())
        await PainterroService.saveImage(id, formData)
        saveCallback(id)
        done(closeOnSave)
        // doClose()
      },
      // onBeforeClose() {
      //   closeCallBack()
      //   doClose()
      // }
      onClose() {
        closeCallBack()
      }
    }).show(image)
    // })
  }
}

const OpenImageLink = async function(link, saveCallback, closeCallBack, closeOnSave = false) {

    const { default: Painterro } = await import(/* webpackChunkName: "painterro-chunk" */ 'painterro')
    Painterro({
      hiddenTools: ['open'],
      defaultTool: 'arrow',
      defaultArrowLength: 15,
      colorScheme: {
        control: '#008cba',
        controlContent: '#ffffff',
        hoverControl: '#006687',
        hoverControlContent: '#ffffff',
        activeControl: '#006687',
        activeControlContent: '#ffffff',
        inputText: 'black',
        toolControlNameColor: '#5bc0de'
      },
      saveHandler: async function(image, done) {
        await saveCallback(image.asBlob(defaultMimeType))
        done(closeOnSave)
      },
      onClose() {
        closeCallBack()
      }
    }).show(link)
}

// export default VuePainterro
export { OpenImage, OpenImageLink }